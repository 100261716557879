import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TextField } from '@material-ui/core';
import React, { useState } from 'react'
import {obtenerDataTablas} from './../utils'


const Menu = ({setData}) => {

    const [nmin, setNmin] = useState(1);
    const [nmax, setNmax] = useState(7);
    const [naprob, setNaprob] = useState(4);
    const [exig, setExig] = useState(60);
    const [pmax, setPmax] = useState(100);
    const [incre, setIncre] = useState(1);
    
    const [open, setOpen] = useState(false);

    const calcularTablas = () => {

        if(nmin < 0 || nmax < 1 || naprob <1  || exig <1 || pmax <1 || incre <1
            || nmin === '' || nmax  === '' || naprob  === ''|| exig  === '' || pmax === '' || incre === ''){
                setOpen(true)
        }else{
            const data = obtenerDataTablas({nmin, nmax, naprob, exig, pmax, incre})
            setData({'tablas': data, 'naprob':naprob})
        }
        
    }

    

    return (
        <Paper elevation={3} className='paper' >
            <form id="form" name="form" noValidate={false}>
                <TextField type="number" required id="nmin" label="Nota Mínima" defaultValue={nmin} onChange={(e) => setNmin(e.target.value)}/>
                <TextField type="number" required id="nmax" label="Nota Máxima" defaultValue={nmax} onChange={(e) => setNmax(e.target.value)}/>
                <TextField type="number" required id="naprob" label="Nota Aprobación" defaultValue={naprob} onChange={(e) => setNaprob(e.target.value)}/>
                <TextField type="number" required id="exig" label="Exigencia" defaultValue={exig} onChange={(e) => setExig(e.target.value)}/>
                <TextField type="number" required id="pmax" label="Puntaje Máximo" defaultValue={pmax} onChange={(e) => setPmax(e.target.value)}/>
                <TextField type="number" required id="incre" label="Incremento" defaultValue={incre} onChange={(e) => setIncre(e.target.value)}/>
                <br/><br/>
                <Button variant="contained" color="secondary" onClick={e => {
                    e.preventDefault()
                    calcularTablas()
                    }}
                    type='submit'
                >Generar Tabla</Button>

            </form>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Problema con datos"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Los datos ingreados son incorrectos, por favor ingreselos nuevamente.</p>
                        <p>Los valores ingresados no pueden ser vacío ni menores a 1 (excepto Nota Mínima).</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"  onClick={() => setOpen(false)} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    
    
)
}

export default Menu;
