

export const obtenerDataTablas = ({nmin, nmax, naprob, exig, pmax, incre}) => {

    let tablas = []
    let tabla = []

    let contadorRows = 0
    for (let p = 0; p < Number(pmax)+1; p = p + Number(incre)) {
        contadorRows++;    
        let valor = Number(pmax) *  Number(exig) / 100

        let notaObtenida = p < valor 
            ? 
                ((Number(naprob) - Number(nmin)) * (p / valor)) + Number(nmin)
            :
                ((Number(nmax) - Number(naprob)) * (p - valor)/(Number(pmax) * (1-( Number(exig) /100)))) + Number(naprob)
            ;
        notaObtenida = notaObtenida.toFixed(1)
    
        tabla.push({
            "puntaje": p,
            "nota": notaObtenida
        })  
        
        if(contadorRows === 10){
            tablas.push(tabla)
            tabla = [];
            contadorRows = 0
        }

    }

    if(contadorRows !== 0)
        tablas.push(tabla)

    console.log(tablas)

    return tablas;

}


