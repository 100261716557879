import { AppBar, Toolbar } from '@material-ui/core';
import React from 'react';
import logo from './../assets/img/logo.png';

const Header = () => (

    <AppBar position='sticky'>
         <Toolbar >
             <img src={logo} alt="logo" width="48" height="48"/>
             &nbsp;&nbsp;
             <h1>Tu Escala de Notas</h1>
         </Toolbar>
    </AppBar>
)

export default Header;