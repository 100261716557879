import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';

const Tablas = ({data}) => {


    const {tablas, naprob} = data
    console.log(naprob)
    return (
        <Grid container spacing={6} justify="center">
            {tablas.map(tabla => (
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'><b>Puntaje</b></TableCell>
                                    <TableCell align='center'><b>Nota</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    tabla.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="center">{row.puntaje}</TableCell>
                                                <TableCell align="center">{row.nota}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )) }
        </Grid> 
    )

}

export default Tablas;
