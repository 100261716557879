import { Box, Grid } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import Menu from './Menu'
import Tablas from './Tablas'

const Body = () => {
   
    const [data, setData] = useState({'tablas': [], 'naprob': 0})

    return (
        <Fragment>
            <Box p={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3} >
                        <Menu setData={setData}/>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Tablas data={data}/>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
        
    )
}
export default Body;
