import React, { Fragment } from 'react';
import Body from './components/Body';
import Footer from './components/Footer';
import Header from './components/Header';


const App = () => (

  <Fragment>
    <Header/>
    <Body />
    <Footer/>
  </Fragment>
)

export default App;
